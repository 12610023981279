import React from 'react'
import * as PropTypes from 'prop-types'
import { useLocation } from '@reach/router'
import styled from 'styled-components'
import { MdPrint } from '@react-icons/all-files/md/MdPrint'
import { FaFacebookF } from '@react-icons/all-files/fa/FaFacebookF'
import { FaTwitter } from '@react-icons/all-files/fa/FaTwitter'
import { FaLinkedinIn } from '@react-icons/all-files/fa/FaLinkedinIn'
import { FaEnvelope } from '@react-icons/all-files/fa/FaEnvelope'
import UnstyledButton from 'components/button/unstyled-button'
import { gtag, gtagEvents, isBrowser } from '@hutson/utils'

const SocialShare = ({ color, label, print, title }) => {
  const location = useLocation()

  const handleShare = e => {
    const method = e.currentTarget.attributes['data-network'].value
    if (method) {
      gtag('event', gtagEvents.share, {
        method,
        // TODO: Add content_type (and item_id?)
        // See https://developers.google.com/tag-platform/gtagjs/reference/events#share
        // content_type: '',
      })
    }
  }

  const handlePrint = () => {
    if (isBrowser) {
      // TODO: Add parameters
      gtag('event', gtagEvents.print_page)
      window.print()
    }
  }
  return (
    <Container color={color}>
      {label && <p>Share:</p>}

      <a
        href={`https://www.facebook.com/sharer.php?u=${encodeURIComponent(location.href)}`}
        target='_blank'
        rel='noopener noreferrer'
        title='Share on Facebook'
        aria-label='Share on Facebook'
        data-network='facebook'
        onClick={handleShare}
      >
        <FaFacebookF aria-hidden='true' focusable='false' role='presentation' />
      </a>

      <a
        href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(location.href)}`}
        target='_blank'
        rel='noopener noreferrer'
        title='Share on Twitter'
        aria-label='Share on Twitter'
        data-network='twitter'
        onClick={handleShare}
      >
        <FaTwitter aria-hidden='true' focusable='false' role='presentation' />
      </a>

      <a
        href={`http://www.linkedin.com/shareArticle?${new URLSearchParams({
          mini: true,
          url: location.href,
          title,
          source: 'hutsoninc.com',
        }).toString()}`}
        target='_blank'
        rel='noopener noreferrer'
        title='Share on LinkedIn'
        aria-label='Share on LinkedIn'
        data-network='linkedin'
        onClick={handleShare}
      >
        <FaLinkedinIn aria-hidden='true' focusable='false' role='presentation' />
      </a>

      <a
        href={`mailto:?body=${encodeURIComponent(location.href)}`}
        data-rel='external'
        title='Share by Email'
        aria-label='Share by Email'
        data-network='email'
        onClick={handleShare}
      >
        <FaEnvelope aria-hidden='true' focusable='false' role='presentation' />
      </a>

      {print && (
        <UnstyledButton onClick={handlePrint} title='Print this page' aria-label='Print this page'>
          <MdPrint aria-hidden='true' focusable='false' role='presentation' />
        </UnstyledButton>
      )}
    </Container>
  )
}

SocialShare.propTypes = {
  color: PropTypes.oneOf(['gray', 'white']).isRequired,
  label: PropTypes.bool.isRequired,
  print: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
}

SocialShare.defaultProps = {
  color: 'gray',
  label: false,
  print: true,
  title: '',
}

const Container = styled.div`
  p {
    color: #7d7d7d;
    display: inline-block;
  }

  a,
  button {
    color: ${props => (props.color === 'white' ? '#fff' : '#9d9d9d;')};
    cursor: pointer;
    display: inline-block;
    margin: 0;
    font-size: 1.35em;

    :hover,
    :focus {
      color: ${props => (props.color === 'white' ? '#fff' : '#7d7d7d;')};
    }

    :not(:first-child) {
      margin: 0 0 0 15px;
    }
  }

  @media (min-width: 800px) {
    a,
    button {
      font-size: 1em;

      :not(:first-child) {
        margin: 0 0 0 10px;
      }
    }
  }
`

export default SocialShare

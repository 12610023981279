import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import Layout from 'components/layout'
import Content from 'components/content'
import H2 from 'components/typography/h2'
import { Helmet } from 'react-helmet'
import SocialShare from 'components/social/social-share'
import { organizationSchema } from 'data/schema-data'
import { graphql, Link } from 'gatsby'
import { column } from 'styles/column'
import { clearfix } from 'styles/clearfix'
import slugify from 'utils/slugify'
import Breadcrumbs from 'components/breadcrumbs'
import BreadcrumbsScript from 'components/scripts/breadcrumbs'
import { FaArrowLeft } from '@react-icons/all-files/fa/FaArrowLeft'
import { GatsbyImage, getSrc } from 'gatsby-plugin-image'
import ProductCarousel from 'components/product-carousel'
import UnstyledButton from 'components/button/unstyled-button'

const goBack = () => {
  window.history.back()
}

const KnowledgeCenterPostTemplate = props => {
  const { breadcrumbs, data, linkedProducts, meta, related } = getInitialProps(props)
  return (
    <Layout kind='white'>
      <Helmet>
        <title>{meta.title}</title>
        <meta name='description' content={meta.description} />
        <meta name='keywords' content={meta.keywords} />
        <meta property='og:title' content={data.frontmatter.title} />
        <meta property='og:description' content={meta.description} />
        <meta property='og:type' content='article' />
        {data.frontmatter.tags.map(tag => (
          <meta property='article:tag' content={tag} key={`Meta_Article_Tag_${tag}`} />
        ))}
        <meta
          property='og:image'
          content={`https://www.hutsoninc.com${getSrc(data.fields.featuredImage)}`}
        />
        <meta property='og:image:alt' content={data.fields.imageAlt} />
        <meta property='og:url' content={meta.url} />
        <meta property='twitter:card' content='summary' />
        <meta name='twitter:site' content='@Hutson_Inc' />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BlogPosting',
            'headline': data.frontmatter.title,
            'articleBody': data.excerpt,
            'image': `https://www.hutsoninc.com${getSrc(data.fields.featuredImage)}`,
            'wordCount': data.wordCount.words,
            'publisher': organizationSchema,
            'dateCreated': data.fields.publishedDate,
            'datePublished': data.fields.publishedDate,
            'dateModified': data.fields.updatedDate,
            'keywords': meta.keywords,
            'url': meta.url,
            'mainEntityOfPage': {
              '@type': 'WebPage',
              '@id': meta.url,
            },
          })}
        </script>
      </Helmet>

      <BreadcrumbsScript breadcrumbs={breadcrumbs} />

      <StyledContent>
        <PostHeader>
          <BreadcrumbsContainer>
            <BackButton onClick={goBack}>
              <FaArrowLeft aria-hidden='true' focusable='false' role='presentation' /> Back
            </BackButton>
            <StyledBreadcrumbs breadcrumbs={breadcrumbs} />
          </BreadcrumbsContainer>
          <GatsbyImage
            image={data.fields.featuredImage.childImageSharp.gatsbyImageData}
            alt={data.fields.featuredImageAlt}
            style={{ width: '100%' }}
          />
        </PostHeader>
        <PostContent>
          <Title>{data.frontmatter.title}</Title>
          <PostBody
            dangerouslySetInnerHTML={{
              __html: data.html,
            }}
          />
          <UpdatedDate>Last updated: {data.fields.updatedDateString}</UpdatedDate>
          <PostFooter>
            <TagsContainer>
              {data.frontmatter.tags.map(tag => (
                <Tag key={`PostFooter_TagsContainer_${tag}`}>
                  <Link to={`/knowledge-center/tags${slugify(tag)}`}>{tag}</Link>
                </Tag>
              ))}
            </TagsContainer>
            <SocialContainer>
              <SocialShare title={data.frontmatter.feature} />
            </SocialContainer>
          </PostFooter>
        </PostContent>
      </StyledContent>

      {linkedProducts && linkedProducts.length > 0 && (
        <LinkedProducts>
          <H2 textAlign='center'>Equipment</H2>
          <ProductCarousel products={linkedProducts} />
        </LinkedProducts>
      )}

      {related.length > 0 && <Related related={related} />}
    </Layout>
  )
}

const StyledContent = styled(Content)`
  @media (min-width: 800px) {
    max-width: 800px;
  }
`

const PostHeader = styled.div`
  margin: 0 auto 40px;
  width: 90%;

  @media (min-width: 800px) {
    margin-bottom: 60px;
    width: 800px;
  }
`

const PostContent = styled.div`
  margin: 0 auto;
  width: 90%;

  @media (min-width: 800px) {
    width: 600px;
  }
`

const BreadcrumbsContainer = styled.div`
  margin-bottom: 20px;
`

const BackButton = styled(UnstyledButton)`
  background-color: #efefef;
  color: #7d7d7d;
  display: inline-block;
  font-size: 1.1em;
  padding: 10px 15px;

  :hover,
  :focus {
    background-color: #dfdfdf;
  }

  svg {
    color: #7d7d7d;
    font-size: 0.8em;
    margin: -3px 2px 0 0;
    vertical-align: middle;
  }

  @media (min-width: 800px) {
    cursor: pointer;
    font-size: 1em;
    margin: 0 15px 0 0;
    padding: 5px 10px;

    svg {
      font-size: 0.7em;
      margin: -2px 1px 0 0;
    }
  }
`

const StyledBreadcrumbs = styled(Breadcrumbs)`
  display: none;

  @media (min-width: 800px) {
    display: inline-block;
    margin-bottom: 0;
  }
`

const Title = styled.h1`
  font-size: 32px;
  font-weight: 600;
  margin: 0;
`

const PostBody = styled.div`
  color: #333;
  font-size: 18px;
  font-weight: 400;
  margin-top: 24px;

  a {
    color: #377539;
    font-weight: 600;
    text-decoration: none;
    vertical-align: bottom;
    text-decoration: underline;
  }

  p {
    font-size: 18px;
    line-height: 1.75;
    margin: 12px 0 18px;
  }

  strong {
    font-weight: 600;
    vertical-align: bottom;
  }

  ul,
  ol {
    margin: 0 0 0 12px;

    li {
      font-size: 18px;
      line-height: 1.75;
      margin: 3px 0;
      padding: 0;

      a {
        color: #377539;
        text-decoration: none;
        vertical-align: bottom;
        text-decoration: underline;
      }

      p {
        margin: 0;
        padding: 0;
      }
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
    max-width: none;
    text-align: left;
    text-transform: none;
  }

  h2 {
    font-size: 28px;
    margin: 24px 0 6px;
  }

  h3 {
    font-size: 24px;
    margin: 20px 0 6px;
  }

  h4 {
    font-size: 21px;
    margin: 18px 0 6px;
  }

  h5 {
    font-size: 20px;
    margin: 18px 0 6px;
  }

  h6 {
    font-size: 18px;
    margin: 18px 0 6px;
  }

  table {
    border: 1px solid #d7d7d7;
    border-collapse: collapse;
    display: block;
    text-align: left;
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;

    thead {
      font-weight: 600;

      tr {
        border-bottom: 1px solid #d7d7d7;
      }
    }

    tr {
      :not(:last-of-type) {
        border-bottom: 1px solid #d7d7d7;
      }
    }

    td {
      padding: 8px;

      :not(:last-of-type) {
        border-right: 1px solid #d7d7d7;
      }
    }
  }

  img {
    display: block;
    margin: 45px auto;
    max-width: 100%;
  }

  figure {
    margin: 45px auto;
    img {
      margin: 0 auto;
    }
  }

  figcaption {
    font-size: 16px;
    font-style: italic;
    font-weight: lighter;
    line-height: 1;
    margin: 12px auto 0;
    text-align: center;
  }

  .iframe {
    height: 0px;
    margin: 45px auto;
    padding-bottom: 56.25%;
    position: relative;
    width: 100%;
  }

  iframe {
    position: absolute;
    height: 100%;
    left: 0px;
    top: 0px;
    width: 100%;
  }
`

const UpdatedDate = styled.div`
  color: #7d7d7d;
  font-style: italic;
  font-weight: lighter;
  margin-top: 30px;
`

const PostFooter = styled.div`
  border-top: 1px solid #ebebeb;
  margin-top: 30px;
  padding-top: 30px;

  @media (min-width: 800px) {
    ${clearfix}
  }
`

const TagsContainer = styled.div`
  @media (min-width: 800px) {
    ${column('5/8')}
  }
`

const Tag = styled.div`
  display: inline-block;
  margin: 0 10px 10px 0;

  a {
    background-color: #ebebeb;
    color: #929292;
    display: block;
    font-size: 1em;
    font-weight: lighter;
    line-height: 1;
    margin: 0;
    padding: 6px 10px;
    text-decoration: none;

    :hover,
    :focus {
      background-color: #dbdbdb;
      color: #828282;
    }
  }

  @media (min-width: 800px) {
    a {
      font-size: 0.8em;
    }
  }
`

const SocialContainer = styled.div`
  margin-top: 30px;
  text-align: left;

  a {
    font-size: 1.25em;
    margin: 0 10px 0 0;
  }

  @media (min-width: 800px) {
    ${column('3/8')}
    margin-top: 0;
    text-align: right;

    a {
      font-size: 1em;
      margin: 0 0 0 10px;
    }
  }
`

const LinkedProducts = styled.div`
  margin-bottom: 40px;

  @media (min-width: 800px) {
    margin-bottom: 60px;
  }
`

const Related = ({ related }) => {
  return (
    <RelatedContainer>
      <H2 textAlign='center'>Related Resources</H2>
      <RelatedWrapper>
        {related.map(
          ({ feature, featuredImage, featuredImageAlt, slug, tags, updatedDateString }, key) => (
            <RelatedPost key={key}>
              <Link to={slug}>
                <GatsbyImage
                  image={featuredImage.childImageSharp.gatsbyImageData}
                  alt={featuredImageAlt}
                  style={{ width: '100%' }}
                />
              </Link>
              <Link to={slug}>
                <div className='related-post-title'>{feature}</div>
                <div className='related-post-date'>{updatedDateString}</div>
              </Link>
              <div className='related-post-tags'>
                {tags.map((cat, key) => (
                  <div className='related-post-category' key={key}>
                    <Link to={`/knowledge-center/tags${slugify(cat)}`}>{cat}</Link>
                  </div>
                ))}
              </div>
            </RelatedPost>
          )
        )}
      </RelatedWrapper>
    </RelatedContainer>
  )
}

Related.propTypes = {
  related: PropTypes.arrayOf(
    PropTypes.shape({
      tags: PropTypes.arrayOf(PropTypes.string).isRequired,
      featuredImage: PropTypes.object.isRequired,
      featuredImageAlt: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      feature: PropTypes.string.isRequired,
    })
  ).isRequired,
}

const RelatedContainer = styled.div`
  background-color: #f2f0f0;
  padding: 40px 0;
  width: 100%;

  @media (min-width: 800px) {
    padding: 60px 0;
  }

  @media print {
    display: none !important;
  }
`

const RelatedWrapper = styled(Content)`
  margin: auto;
  padding: 0;

  @media (min-width: 800px) {
    ${clearfix}
  }
`

const RelatedPost = styled.div`
  background-color: #fff;
  border: 1px solid #d7d7d7;
  box-shadow: none;
  transition: box-shadow 0.2s;

  :hover,
  :focus {
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  }

  a {
    text-decoration: none;

    .related-post-title {
      color: #222;
    }

    :hover,
    :focus {
      .related-post-title {
        text-decoration: underline;
      }
    }
  }

  :not(:last-of-type) {
    margin-bottom: 30px;
  }

  .related-post-title {
    font-size: 1.3em;
    font-weight: 600;
    padding: 20px 20px 0;
  }

  .related-post-date {
    color: #b3b3b3;
    font-size: 1em;
    font-weight: 400;
    margin-top: 15px;
    padding: 0 20px;
  }

  .related-post-tags {
    margin-top: 20px;
    padding: 0 20px 20px;
  }

  .related-post-category {
    display: inline-block;
    margin: 0 10px 10px 0;

    a {
      background-color: #ebebeb;
      color: #929292;
      display: block;
      font-size: 0.9em;
      font-weight: lighter;
      line-height: 1;
      margin: 0;
      padding: 4px 8px;
      text-decoration: none;

      :hover,
      :focus {
        background-color: #dbdbdb;
        color: #828282;
      }
    }
  }

  @media (min-width: 800px) {
    ${column('1/3')}

    :not(:last-of-type) {
      margin-bottom: 0;
    }
  }
`

const getLinkedProducts = ({ allDeereProduct, allSanityStihlEquipment }) => {
  const linkedProducts = [
    ...allDeereProduct.nodes.map(obj => {
      return {
        sku: obj.sku,
        slug: obj.slug,
        image: obj.image,
        imageAlt: obj.imageAlt,
        name: obj.name,
      }
    }),
    ...allSanityStihlEquipment.nodes.map(node => {
      return {
        sku: node.sku,
        slug: node.slug,
        image: getSrc(node.firstImage.asset.gatsbyImageData),
        imageAlt: node.title,
        name: node.title,
      }
    }),
  ]
  return linkedProducts
}

const getInitialProps = ({
  data: { allDeereProduct, allMarkdownRemark, allSanityStihlEquipment, markdownRemark },
}) => {
  const meta = {
    title: `${markdownRemark.frontmatter.title} | Hutson Inc`,
    description: markdownRemark.excerpt.slice(0, 157) + '...',
    keywords: [...markdownRemark.frontmatter.tags].toString(),
    url: `https://www.hutsoninc.com/knowledge-center${markdownRemark.fields.slug}`,
  }

  const breadcrumbs = [
    {
      name: 'Home',
      schemaName: 'Hutson Inc',
      link: '/',
    },
    {
      name: 'Knowledge Center',
      link: '/knowledge-center/',
    },
    {
      name: markdownRemark.frontmatter.feature,
      link: markdownRemark.fields.slug,
    },
  ]

  const related = allMarkdownRemark.nodes.map(({ fields, frontmatter, ...node }) => {
    return {
      ...fields,
      ...frontmatter,
      ...node,
    }
  })

  const linkedProducts = getLinkedProducts({
    allDeereProduct,
    allSanityStihlEquipment,
  })

  return {
    breadcrumbs,
    data: markdownRemark,
    linkedProducts,
    meta,
    related,
  }
}

export const pageQuery = graphql`
  query knowledgeCenterPostTemplate($id: String!, $linkedProducts: [String!], $related: [String!]) {
    markdownRemark(id: { eq: $id }) {
      excerpt(pruneLength: 10000)
      wordCount {
        words
      }
      frontmatter {
        feature
        tags
        title
      }
      fields {
        featuredImage {
          ...SharpImage800
        }
        featuredImageAlt
        publishedDate
        publishedDateString: publishedDate(formatString: "MMMM DD, YYYY")
        slug
        updatedDate
        updatedDateString: updatedDate(formatString: "MMMM DD, YYYY")
      }
      html
    }
    allMarkdownRemark(filter: { id: { in: $related } }) {
      nodes {
        frontmatter {
          feature
          tags
        }
        fields {
          featuredImage {
            ...SharpImage680
          }
          featuredImageAlt
          slug
          updatedDateString: updatedDate(formatString: "MMMM DD, YYYY")
        }
      }
    }
    allDeereProduct(
      filter: { sku: { in: $linkedProducts } }
      sort: { fields: [name], order: ASC }
    ) {
      nodes {
        image
        imageAlt
        name
        overview
        sku
        slug
      }
    }
    allSanityStihlEquipment(
      filter: { sku: { in: $linkedProducts }, published: { eq: true } }
      sort: { fields: [title], order: ASC }
    ) {
      nodes {
        firstImage {
          asset {
            gatsbyImageData(layout: FIXED, width: 360)
          }
        }
        slug
        sku
        title
      }
    }
  }
`

export default KnowledgeCenterPostTemplate

import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Carousel, { consts } from 'react-elastic-carousel'
import formatProductTitle from 'utils/format-product-title'
import { FiChevronLeft } from '@react-icons/all-files/fi/FiChevronLeft'
import { FiChevronRight } from '@react-icons/all-files/fi/FiChevronRight'
import UnstyledButton from 'components/button/unstyled-button'

const ProductCarousel = ({
  breakPoints,
  ChildContainer,
  products,
  renderArrow,
  renderPagination,
}) => {
  return (
    <Carousel
      renderArrow={renderArrow}
      renderPagination={renderPagination}
      breakPoints={breakPoints}
    >
      {products.map(prod =>
        ChildContainer ? (
          <ChildContainer {...prod} key={prod.sku} />
        ) : (
          <CarouselContainer product={prod} key={prod.sku} />
        )
      )}
    </Carousel>
  )
}

ProductCarousel.propTypes = {
  breakPoints: PropTypes.arrayOf(
    PropTypes.shape({
      itemsToShow: PropTypes.number.isRequired,
      itemsToScroll: PropTypes.number,
      width: PropTypes.number.isRequired,
    }).isRequired
  ),
  ChildContainer: PropTypes.func,
  renderArrow: PropTypes.func,
  renderPagination: PropTypes.func,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      imageAlt: PropTypes.string,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}

const renderArrowDefault = ({ type, onClick }) => {
  const pointer =
    type === consts.PREV ? (
      <FiChevronLeft aria-hidden='true' focusable='false' role='presentation' />
    ) : (
      <FiChevronRight aria-hidden='true' focusable='false' role='presentation' />
    )
  return (
    <CarouselArrow aria-label={consts.PREV ? 'Previous item' : 'Next item'} onClick={onClick}>
      {pointer}
    </CarouselArrow>
  )
}

const renderPaginationDefault = ({ pages, activePage, onClick }) => {
  return (
    <nav aria-label='pagination'>
      {pages.map(page => {
        return (
          <Dot
            aria-current={activePage === page ? 'page' : null}
            aria-label={page + 1}
            key={`CarouselPagination${page}`}
            onClick={() => onClick(page)}
          />
        )
      })}
    </nav>
  )
}

ProductCarousel.defaultProps = {
  breakPoints: [
    { width: 1, itemsToShow: 1 },
    { width: 480, itemsToShow: 2, itemsToScroll: 2 },
    { width: 800, itemsToShow: 3, itemsToScroll: 3 },
    { width: 1024, itemsToShow: 4, itemsToScroll: 4 },
  ],
  renderArrow: renderArrowDefault,
  renderPagination: renderPaginationDefault,
}

const CarouselArrow = styled(UnstyledButton)`
  align-items: flex-start;
  align-self: center;
  background-color: #dfdfdf;
  box-sizing: border-box;
  color: #7d7d7d;
  cursor: pointer;
  direction: ltr;
  display: block;
  font-size: 25.6px;
  margin: 0;
  padding: 10px 0;
  position: relative;
  text-align: center;
  width: 35px;

  :hover,
  :focus {
    background-color: #cfcfcf;
    color: #6d6d6d;
  }
`

const Dot = styled(UnstyledButton)`
  background-color: ${props => (props['aria-current'] === 'page' ? '#377539' : '#fff')};
  border-radius: 100%;
  border: 1px solid #377539;
  cursor: pointer;
  display: inline-block;
  height: 12px;
  margin: 0 5px;
  width: 12px;
`

const StyledCarouselContainer = styled.div`
  margin: 10px;
  padding: 0;
  text-align: center;
  width: 100%;

  > a {
    background-color: #fff;
    border: 1px solid #d7d7d7;
    box-shadow: none;
    color: #377539;
    display: block;
    font-size: 1.1em;
    font-weight: 600;
    text-decoration: none;
    transition: box-shadow 0.2s;

    :hover,
    :focus {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

      .carousel-item-copy {
        text-decoration: underline;
      }
    }
  }

  .carousel-item {
    padding: 20px;
  }

  .carousel-item-image {
    display: block;
    height: 0px;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    width: auto;

    img {
      display: block;
      height: auto;
      left: 50%;
      max-height: 100%;
      max-width: 100%;
      object-fit: contain;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: auto;
    }
  }

  .carousel-item-copy {
    padding-top: 10px;
  }
`

const CarouselContainer = ({ product: { sku, slug, image, imageAlt, name } }) => {
  return (
    <StyledCarouselContainer>
      <Link to={slug}>
        <div className='carousel-item'>
          <div className='carousel-item-image'>
            <img src={image} alt={`${imageAlt || name}`} loading='lazy' />
          </div>
          <div className='carousel-item-copy'>{formatProductTitle(sku, name)}</div>
        </div>
      </Link>
    </StyledCarouselContainer>
  )
}

export default ProductCarousel
